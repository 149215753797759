import { mapState } from 'vuex';

import {
  fecthPostSubject,
  fecthPutSubject,
  fetchGetSubject,
} from '../../helpers/subjectsOptions';
import {
  fetchDeleteCriterion,
  fetchPostCriterion,
  fetchPutCriterion,
} from '../../helpers/criterionOptions';
import { getItem } from '../../../../api/api-methods';
import { validationFormMixin } from '../../../shared/mixins/validationFormMixin';

import KnAutoComplete from '../../../shared/components/KnAutoComplete.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnCheckBox from '../../../shared/components/KnCheckBox.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormNoteOfMandatory from '../../../shared/components/KnFormNoteOfMandatory.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnTabs from '../../../shared/components/KnTabs.vue';
import KnTextArea from '../../../shared/components/KnTextArea.vue';
import KnTextField from '../../../shared/components/KnTextField.vue';

export default {
  components: {
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormNoteOfMandatory,
    KnFormTitle,
    KnAutoComplete,
    KnCheckBox,
    KnTextArea,
    KnTextField,
    KnLocalAlert,
    KnTabs,
  },
  mixins: [validationFormMixin],
  watch: {
    'form.criterios_evaluacion': {
      handler(newVal) {
        newVal.forEach((criterion, index) => {
          if (criterion.id) this.updateCriterion(index);
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    totalPercentage() {
      return this.form.criterios_evaluacion
        .reduce((acc, criterio) => {
          return acc + parseFloat(criterio.porcentaje || 0);
        }, 0)
        .toFixed(2);
    },
    formTitle() {
      if (this.subjectId) return 'Editar materia';
      return 'Agregar materia';
    },
    alertPercentage() {
      const total = parseFloat(this.totalPercentage);
      if (total === 100.0) return 'success';
      return total > 100.0 ? 'error' : 'info';
    },
    invalidFields() {
      const { form } = this;
      const hasValidName = form && form.nombre && form.nombre.trim() !== '';
      const hasValidTitle =
        form &&
        typeof form.titular_materia === 'number' &&
        form.titular_materia > 0;
      const allCriteriaValid =
        form &&
        Array.isArray(form.criterios_evaluacion) &&
        form.criterios_evaluacion.every((criterio) => {
          const hasName = criterio.nombre && criterio.nombre.trim() !== '';
          const hasDescription =
            criterio.descripcion && criterio.descripcion.trim() !== '';
          const hasValidPercentage =
            !isNaN(parseFloat(criterio.porcentaje)) &&
            parseFloat(criterio.porcentaje) >= 0;

          return hasName && hasDescription && hasValidPercentage;
        });
      return hasValidName && hasValidTitle && allCriteriaValid;
    },
  },
  data() {
    return {
      subjectId: null,
      routerName: 'Materias',
      buttonLabel: 'Completar Formulario',
      criteriaButtonLabel: 'Criterios de Evaluación',
      valueDeterminate: 49,
      tabs: [
        { name: 'Informacion general', value: 49 },
        { name: 'Criterios', value: 99 },
      ],
      form: {},
      selectedCriterios: [],
      initialCriteria: [],
      addedCriteria: [],
      removedCriteria: [],
      modifiedCriteria: [],
      readonly: false,
      valid: false,
      learningField: [],
      curriculum: [],
      preparatoryArea: [],
      courseInstructors: [],
      showAlert: false,
      alertText: '',
      alertType: '',
      alertColor: null,
      loading: false,
      errors: [],
      loadingPage: false,
    };
  },
  async created() {
    this.loadingPage = true;
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      const paramId = this.$route.params.id;
      if (paramId) {
        this.subjectId = this.validateId(paramId);
        await this.loadDataSubject();
      } else this.defaultFormSubject();

      await this.fillDataForm();
    } catch (error) {
      console.error('Error in created:', error);
      this.error = 'Error al cargar datos. ';
      this.setErrorState(
        'Error al cargar datos. Por favor, inténtelo de nuevo.'
      );
    } finally {
      this.loadingPage = false;
      this.setLoadingState(false);
    }
  },
  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion

    defaultFormSubject() {
      this.form = {
        nombre: undefined,
        descripcion: undefined,
        extracurricular: false,
        fecha_elaboracion: undefined,
        titular_materia: undefined,
        plan_estudios: undefined,
        area_propedeutica: undefined,
        institucion_educativa: this.institutionId,
        campos_formativos: [],
        criterios_evaluacion: [],
      };
    },

    validateId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },
    async loadDataSubject() {
      try {
        const { data } = await fetchGetSubject(this.subjectId);
        const {
          titular_materia,
          campos_formativos,
          plan_estudios,
          area_propedeutica,
          criterios_evaluacion,
        } = data;

        this.form = {
          ...data,
          titular_materia: titular_materia ? titular_materia.id : null,
          campos_formativos: campos_formativos
            ? campos_formativos.map((e) => e.id)
            : [],
          plan_estudios: plan_estudios ? plan_estudios.id : null,
          area_propedeutica: area_propedeutica ? area_propedeutica.id : null,
        };
        this.initialCriteria = JSON.parse(JSON.stringify(criterios_evaluacion));
      } catch (error) {
        console.error('Error loading subject data:', error);
      }
    },

    async fillDataForm() {
      const [
        learningFieldRes,
        curriculumRes,
        preparatoryAreaRes,
        courseInstructorsRes,
      ] = await Promise.all([
        getItem(
          `/app-personas/filters/campo-formativo?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        ),
        getItem(
          `/app-personas/filters/plan-estudios?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        ),
        getItem(
          `/app-personas/filters/area-propedeutica?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
        ),
        getItem(
          `/app-personas/filters/empleado?estatus_sistema=true&limit=100&institucion_educativa=${this.institutionId}`
        ),
      ]);

      this.learningField = learningFieldRes.results;
      this.curriculum = curriculumRes.results;
      this.preparatoryArea = preparatoryAreaRes.results;
      this.courseInstructors = courseInstructorsRes.results;
    },
    setTabValue(val) {
      this.valueDeterminate = val;
    },
    addNewCriterion() {
      const newCriterion = {
        id: null,
        nombre: '',
        descripcion: '',
        porcentaje: undefined,
      };
      this.form.criterios_evaluacion.push(newCriterion);
      this.addedCriteria.push(newCriterion);
    },

    removeCriterion(index) {
      const criterion = this.form.criterios_evaluacion[index];
      const { id } = criterion;
      if (id) {
        this.removedCriteria.push(criterion);
      }
      this.form.criterios_evaluacion.splice(index, 1);
      this.initialCriteria.splice(index, 1);
      this.removeFromList(this.addedCriteria, criterion);
      this.removeFromList(this.modifiedCriteria, criterion, true);
    },

    updateCriterion(index) {
      const criterion = this.form.criterios_evaluacion[index];
      const original = this.initialCriteria.find(
        ({ id }) => id === criterion.id
      );
      if (!original) return;
      const isModified = ['nombre', 'descripcion', 'porcentaje'].some(
        (key) => original[key] !== criterion[key]
      );
      if (isModified) return this.updateList(this.modifiedCriteria, criterion);
      this.removeFromList(this.modifiedCriteria, criterion, true);
    },

    selectPopularCriterion(criterion) {
      const newCriterion = { ...criterion, id: null, porcentaje: undefined };
      this.form.criterios_evaluacion.push(newCriterion);
      this.addedCriteria.push(newCriterion);
    },

    async saveCriteria() {
      try {
        await Promise.all(
          this.removedCriteria.map(({ id }) => this.deleteCriterion(id))
        );
        const addedCriteria = await Promise.all(
          this.addedCriteria.map((criterion) => this.addCriterion(criterion))
        );
        await Promise.all(
          this.modifiedCriteria.map((criterion) => this.putCriterion(criterion))
        );
        this.addedCriteria = [];
        this.removedCriteria = [];
        this.modifiedCriteria = [];

        const remainingCriteriaIds = [
          ...this.initialCriteria
            .filter((criterion) => criterion.id)
            .map((criterion) => criterion.id),
          ...addedCriteria.map((criterion) => criterion.id),
        ];

        return remainingCriteriaIds;
      } catch (error) {
        console.error('Error saving criteria:', error);
        throw error;
      }
    },

    async saveSubject() {
      this.setLoadingState(true, 'Actualizando materia..', 'info');
      try {
        const remainingCriteriaIds = await this.saveCriteria();
        await this.putSubject({
          ...this.form,
          criterios_evaluacion: remainingCriteriaIds,
        });
        this.setSuccessState('Materia actualizada exitosamente');
      } catch (error) {
        console.error('Error saving subject:', error);
        throw error;
      }
    },
    async createSubject() {
      this.setLoadingState(true, 'Creando materia..', 'info');
      try {
        const remainingCriteriaIds = await this.saveCriteria();
        const subject = await this.postSubject({
          ...this.form,
          criterios_evaluacion: remainingCriteriaIds,
        });
        if (subject)
          this.setSuccessState(`Materia ${subject.nombre} creada exitosamente`);
        else this.setErrorState('Error al intentar crear materia');
      } catch (error) {
        console.error('Error creating subject:', error);
        throw error;
      }
    },

    removeFromList(list, item, strict = false) {
      const index = list.findIndex((el) =>
        strict ? el === item : el.id === item.id
      );
      if (index !== -1) {
        list.splice(index, 1);
      }
    },

    updateList(list, item) {
      const index = list.findIndex((el) => el.id === item.id);
      if (index === -1) {
        list.push(item);
      } else {
        list[index] = item;
      }
    },
    async addCriterion(criterion) {
      const { ok, data } = await fetchPostCriterion(criterion);
      if (ok && data) {
        return data;
      } else {
        console.error('Failed to add criterion', data);
        throw new Error('Failed to add criterion');
      }
    },
    async putCriterion(criterion) {
      const { ok, data } = await fetchPutCriterion(criterion.id, criterion);
      if (ok && data) {
        return data;
      } else {
        console.error('Failed to update criterion', data);
        throw new Error('Failed to update criterion');
      }
    },
    async deleteCriterion(id) {
      const { ok } = await fetchDeleteCriterion(id);
      if (ok) {
        return id;
      } else {
        console.error('Failed to delete criterion');
        throw new Error('Failed to delete criterion');
      }
    },

    async putSubject(subject) {
      const { ok, data } = await fecthPutSubject(subject.id, subject);
      console.log(ok, data);
    },
    async postSubject(subject) {
      const { ok, data } = await fecthPostSubject(subject);
      if (ok) return data;
    },

    async save() {
      if (this.subjectId) {
        console.log('Guardando...');
        await this.saveSubject();
        await this.loadDataSubject();
      } else {
        console.log('Creando...');
        await this.createSubject();
        await this.loadDataSubject();
      }
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    actionAlertBtn1() {
      this.alertType === 'success' || this.alertType === 'info'
        ? this.returnToTable()
        : this.closeAlert();
    },
    continueAdding() {
      // this.cleanForm()
      this.closeAlert();
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
  },
};
